import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Banner.module.scss'
import images from './img'
import Content from 'components/Content'
import Img from 'gatsby-image'
import Webp from 'components/Webp'

const b = bem.banner(css)

const Banner = ({ title, description, children, image }) => {
	const desc = description ? description.trim() : null
	const { childImageSharp, width, height, alt, name } = image || {}
	const altText = alt || title

	const Image = () => (
		<Webp webp={images[name].webp} png={images[name].png} alt={altText} width={width} height={height} />
	)

	return (
		<div className={b({ 'is-image': !!image })}>
			<div className={b('inner')}>
				<Content className={b('content')}>
					<div className={b('text')}>
						<h1 dangerouslySetInnerHTML={{ __html: title }} />
						{desc && <div className={b('description', 'fs-h4 no-last')} dangerouslySetInnerHTML={{ __html: desc }} />}
						{children && <div className={b('children')}>{children}</div>}
					</div>

					{image && (
						<div className={b('image-wrapper')}>
							{childImageSharp && (
								<div className={b('image-inner')} style={{ width: 500 }}>
									<Img
										className={b('img')}
										fluid={childImageSharp.fluid}
										alt={altText}
										placeholderStyle={{ visibility: 'hidden' }}
									/>
								</div>
							)}
							{!childImageSharp && width && height && (
								<div className={b('image-inner')} style={{ width }}>
									<div className="image">
										<div
											className="image__inner"
											style={{
												paddingTop: `calc(${height} / ${width} * 100%)`,
											}}
										>
											{Image()}
											{/*<img className={b('img')} src={images[name]} alt={altText} />*/}
										</div>
									</div>
								</div>
							)}
							{!childImageSharp && !width && (
								<div className={b('image-inner')}>
									{Image()}
									{/*<img className={b('img')} src={images[name]} alt={altText} />*/}
								</div>
							)}
						</div>
					)}
				</Content>
			</div>
		</div>
	)
}

Banner.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	children: PropTypes.any,
	image: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
		alt: PropTypes.string,
		name: PropTypes.string,
		childImageSharp: PropTypes.object,
	}),
}

export default Banner
